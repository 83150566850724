import React, { useEffect, useState } from 'react';

import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import DocumentTitle from 'react-document-title';
import { differenceInMinutes } from 'date-fns';
import pick from 'lodash/pick';
import queryString from 'query-string';

import relativeDate from '../../lib/relative-date';

import * as siteSelectors from '../../reducers/sitesReducer';
import * as blogActions from '../../actions/blogActions';
import * as tagActions from '../../actions/tagActions';
import * as blogSelectors from '../../reducers/blogsReducer';
import * as uiBlogSettingsActions from '../../actions/uiBlogSettingsActions';
import * as articleActions from '../../actions/articleActions';
import * as articleSelectors from '../../reducers/articlesReducer';
import * as uiArticleSettingsActions from '../../actions/uiArticleSettingsActions';
import { selectAllById, selectCurrentAccountUser } from '../../reducers/accountUsersReducer';
import * as categoriesActions from '../../actions/categoriesActions';
import * as itemsActions from '../../actions/itemsActions';
import { selectAllCategories } from '../../reducers/categoriesReducer';
import { selectHasPermission } from '../../reducers/policyReducer';
import withQueryParams from 'containers/withQueryParams';

import { StatusIndicator } from './PageList';
import BlogSettings from '../Blogs/BlogSettings';
import ArticleSettings from '../Articles/ArticleSettings';
import ZeroState, { Image, Header, Muted } from './ZeroState';
import Search from '../Search';
import icon from '../../assets/images/icon-zero-state-blog.svg';
import ListWithServerSorting from '../react-table/ListWithServerSorting';
import Select from '../Select';
import CategorySettings from '../Articles/CategorySettings';
import isEqual from 'lodash/isEqual';
import ArticlesFilter from './ArticlesFilter';

import { ReactComponent as SvgIconOptions } from '../../assets/images/icon-options.svg';
import { ReactComponent as PrevSvg } from '../../assets/images/icon-prev-page.svg';
import { ReactComponent as NextSvg } from '../../assets/images/icon-next-page.svg';
import { ReactComponent as SvgIconAdd } from '../../assets/images/icon-plus.svg';
import Spinner from '../base/Spinner';

import styles from './Pages.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UPage } from 'types/UPage';
import { OnMount } from 'hooks/mountUnmountHooks';

type Props = {
  createBlog: () => void;
  updateQueryParams: (params: any) => void;
  queryParams: any;
};

//  TODO: Prevent extra intial fetch call
function Blogs(props: Props) {
  const [state, setState] = useState({
    isWatchingForNewBlog: false,
    newBlogId: null,
    query: undefined,
    page: 1,
    selectedFilter: {} as { [key: string]: any },
    filteredPageNumber: 1,
    availableBlogs: [],
    debouncedSearch: '',
  });
  const { urlSite, blogId: urlBlogId } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(blogSelectors.selectIsFetching);
  // @ts-ignore
  const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const blogs = useSelector(blogSelectors.selectAll);
  const siteName = site.name;
  const allArticles = useSelector(articleSelectors.selectAll);
  const articlesCount = useSelector(articleSelectors.selectArticlesCount);
  const articlesIsFetching = useSelector(articleSelectors.selectIsFetching);
  const allUsers = useSelector(selectAllById);
  const currentUser = useSelector(selectCurrentAccountUser);
  const allCategories = useSelector(selectAllCategories);
  const hasPermission = useSelector(selectHasPermission);
  const filters = useSelector(articleSelectors.selectFilters);
  const {
    queryParams: { search, status, order_by, page = 1 },
    updateQueryParams,
  } = props;
  const [blogId, setBlogId] = useState<any>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  /* const mapDispatchToProps = {
  openBlogSettings: uiBlogSettingsActions.open,
  openArticleSettings: uiArticleSettingsActions.open,
};*/

  OnMount(() => {
    dispatch(tagActions.fetchAll());
    dispatch(categoriesActions.requestListIfNeeded());
    const availableBlogs = blogs.map((blog: any) => ({
      value: blog.id,
      label: blog.name,
    }));
    if (availableBlogs.length) {
      setState({ ...state, availableBlogs });
      setBlogId(availableBlogs[0].value);
    }
  });

  useEffect(() => {
    const availableBlogs = blogs.map((blog: any) => ({
      value: blog.id,
      label: blog.name,
    }));
    setState({ ...state, availableBlogs });

    if (blogs.length !== 0 && blogId && blogId !== urlBlogId) {
      setBlogId(blogId || availableBlogs[0].value);
    }
  }, [blogs, urlBlogId, setBlogId]);

  const goToBlogEditor = (blogId: string) => {
    navigate(`/${urlSite}/editor/blog/${blogId}`);
    window.scrollTo({ top: 0 });
  };

  const goToArticleEditor = (articleId: string) => {
    navigate(`/${urlSite}/editor/blog-post/${articleId}`);
    window.scrollTo({ top: 0 });
  };

  const handleClickNewBlog = (e: any) => {
    e.preventDefault();
    setState({ ...state, isWatchingForNewBlog: true });
    props.createBlog();
  };

  const debounceSearch = debounce((search) => {
    props.updateQueryParams({ search });
  }, 300);

  if (isLoading) return <Spinner className="fixed" size="1" />;

  const columns = [
    {
      Header: 'Article Title',
      accessor: 'name',
      Cell: ({ value }: any) => <span>{value}</span>,
      sortable: true,
      minWidth: 360,
    },
    {
      Header: 'Author',
      id: 'author',
      sortable: true,
      Cell: ({ original: value }: any) => {
        return value && allUsers[value.author] ? (
          <>
            {allUsers[value.author].profile_image ? (
              <span
                className={styles.initials}
                style={{
                  ...(allUsers[value.author].profile_image && {
                    backgroundImage: `url(${allUsers[value.author].profile_image.icon})`,
                  }),
                }}
              />
            ) : (
              <span className={styles.initials} data-test-id="initials">
                {allUsers[value.author].initials}
              </span>
            )}
            {differenceInMinutes(new Date(), new Date(value.modified_at)) < 10 && (
              <span className={styles.editStatus} />
            )}
            <span data-test-id="author-name">{allUsers[value.author].name}</span>
          </>
        ) : (
          <span data-test-id="no-author">-</span>
        );
      },
      width: 140,
      getProps: () => {
        return {
          style: {
            position: 'relative',
          },
        };
      },
    },
    {
      Header: 'Last edited',
      accessor: 'modified_at',
      Cell: ({ value }: any) => <span>{relativeDate(value)}</span>,
      width: 150,
      sortable: true,
    },
    {
      Header: 'Publish date',
      accessor: 'publish_date',
      id: 'published_at',
      Cell: ({ value, original }: any) => {
        if (original.status === 'published') {
          return <span>{value}</span>;
        }
        return null;
      },
      width: 140,
      sortable: true,
    },
    {
      Header: 'Categories',
      accessor: 'categories',
      Cell: ({ value }: any) =>
        value && value.length && allCategories.length ? (
          <span>
            {value
              .map((category: any) => (allCategories.find((c: any) => c.id === category) || { name: '' }).name)
              .join(',')}
          </span>
        ) : (
          <span>-</span>
        ),
      width: 130,
      sortable: true,
    },
    {
      Header: '30 day views',
      accessor: 'views',
      Cell: ({ value }: any) => (value ? <span>{value}</span> : <span>-</span>),
      width: 110,
      sortable: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => (
        <div>
          <StatusIndicator disableContainerClass status={value} />
        </div>
      ),
      width: 80,
      sortable: true,
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ value }: any) => (
        <button
          data-tip="Options"
          data-test-id="options"
          className={styles.options}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(uiArticleSettingsActions.open(value));
          }}
        >
          <SvgIconOptions />
        </button>
      ),
      width: 40,
    },
  ];

  return (
    <>
      <DocumentTitle title={`${siteName}'s blog content`} />
      <section>
        <header className={styles.blogsHeader}>
          <div>
            <h1>Manage Articles</h1>
            {blogs.length > 1 ? (
              <span className={styles.filter}>
                :{' '}
                <Select
                  options={state.availableBlogs}
                  isInline
                  value={state.availableBlogs.find((blog) => blog.value === blogId)}
                  onChange={(selectedBlog: any) => {
                    setState({
                      ...state,
                      query: '',
                      page: 1,
                      selectedFilter: filters[0],
                      filteredPageNumber: 1,
                    });
                    setBlogId(selectedBlog.value);
                  }}
                />
              </span>
            ) : (
              <small>Tell your stories. Collaborate with others.</small>
            )}
            <div style={{ clear: 'both' }}></div>
            <div className={styles.blogNavOptions}>
              {hasPermission('Category') && (
                <a
                  role="link"
                  onClick={() => {
                    navigate(`/${urlSite}/blogs/${blogId}/categories`);
                  }}
                >
                  Categories
                </a>
              )}
              {hasPermission('Blog:update') && (
                <>
                  <span />
                  <a onClick={() => goToBlogEditor(blogId)} role="link">
                    Layout
                  </a>
                  <span />
                  <a onClick={() => dispatch(uiBlogSettingsActions.open(blogId))} role="link">
                    Settings
                  </a>
                </>
              )}
            </div>
          </div>
          <div className={styles.searchFieldContainer}>
            <Search
              value={state.query === undefined ? search : state.query}
              onChange={(query: string) => setState({ ...state, query })}
              placeholder="Search articles by name"
              debounceValue={debounceSearch}
              containerStyle={{
                marginTop: 0,
                marginRight: '1rem',
              }}
            />
            <div
              onClick={() => navigate(`/${urlSite}/blog/${blogId}/new`)}
              className={styles.newContactButton}
              data-test-id="new-article"
            >
              <SvgIconAdd height={15} width={15} />
              <span>New Article</span>
            </div>
          </div>
        </header>

        <ArticlesFilter
          filter={filters.find((filter) => filter.value === status) || filters[0]}
          setFilter={(status: any) => updateQueryParams({ status: status.value })}
          filters={filters}
        />
      </section>
      {articlesIsFetching ? (
        <Spinner className="fixed" />
      ) : allArticles.length ? (
        <ListWithServerSorting
          className="-highlight"
          data={allArticles}
          columns={columns}
          count={articlesCount}
          defaultPageSize={10}
          getTdProps={(state: any, row: any) => ({
            onClick: (e: any) => {
              row.original.is_deleted
                ? dispatch(uiArticleSettingsActions.open(row.original.id))
                : goToArticleEditor(row.original.id);
            },
            style: { cursor: 'pointer' },
          })}
          loading={false}
          showViewAll={false}
          breakPageslabel={<span>...</span>}
          nextPageLabel={<NextSvg />}
          onPageChange={(page: UPage) => updateQueryParams({ page })}
          pagesCount={articlesCount / 10}
          paginate
          activePage={page}
          previousPageLabel={<PrevSvg />}
          showHeader
          getTheadProps={() => ({
            style: {
              zIndex: '0',
            },
          })}
          orderBy={order_by}
          onSortChange={(newSort: any) => {
            updateQueryParams({
              order_by: `${newSort.desc ? '-' : ''}${newSort.id}`,
            });
          }}
        />
      ) : search || state.selectedFilter.value ? (
        <ZeroState>
          <Image src={icon} />
          <Header>No article(s) match your criteria</Header>
          <Muted>
            Your filter and search criteria does not match a name in your articles' list. Try a different set of
            criteria.
          </Muted>
        </ZeroState>
      ) : (
        <ZeroState>
          <Image src={icon} />
          <Header>No articles exist for this blog</Header>
          <Muted>You'll see all articles for this blog here</Muted>
        </ZeroState>
      )}
      <BlogSettings />
      <ArticleSettings blogId={blogId} />
      <CategorySettings
        isOpen={location.pathname.includes('/categories')}
        close={() => navigate(`/${urlSite}/blogs`)}
      />
    </>
  );
}

export default withQueryParams(Blogs, {
  itemType: 'article',
  panel: 'items',
});
